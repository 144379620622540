import React from "react";
import { Radar } from "react-chartjs-2";

const RadarData = {
  labels: [
    "Marketing / Ügyfélszerzés",
    "Onboarding",
    "Kommunikáció",
    "Feladat menedzsment",
    "Dokumentum kezelés",
    "Adatrögzítés / könyvelés",
  ],
  datasets: [
    {
      label: "Elért pont",
      backgroundColor: "rgba(34, 202, 236, .2)",
      borderColor: "rgba(34, 202, 236, 1)",
      pointBackgroundColor: "rgba(34, 202, 236, 1)",
      poingBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(34, 202, 236, 1)",
      data: [60, 80, 15, 90, 55, 70],
    },
  ],
};
const RadarOptions = {
  scale: {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
      showLabelBackdrop: false,
      backdropColor: "rgba(203, 197, 11, 1)",
    },
    angleLines: {
      color: "rgba(255, 255, 255, .3)",
      lineWidth: 1,
    },
    gridLines: {
      color: "rgba(255, 255, 255, .3)",
      circular: true,
    },
  },
};

export class RadarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }
  componentDidMount() {
    RadarData.datasets[0].data = this.props.scores;
  }

  render() {
    return (
      <Radar ref={this.chartRef} data={RadarData} options={RadarOptions} />
    );
  }
}
