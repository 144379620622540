import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StartImg from "../../../assets/images/start-img.png";
import StartImg1 from "../../../assets/images/start-img1.png";
import StartImg2 from "../../../assets/images/start-img2.png";

function StartScreen({ ...props }) {
  return (
    <Grid container direction="column" justify="center">
      <Grid container justify="space-around">
        <Grid
          item
          sm={6}
          className="FlexCentered"
          style={{
            flexDirection: "column",
            zIndex: 100,
            justifyContent: "flex-start",
          }}
        >
          <h2
            style={{
              fontSize: "1.4em",
              textAlign: "center",
              marginBottom: "2em",
            }}
          >
            <span style={{ color: "#0097a7" }}>
              Digitális Könyvelő <br />
              Minősítés
            </span>{" "}
          </h2>
          <Button onClick={props.startSurvey} className="ButtonStyle">
            Minősítem magam!
          </Button>
        </Grid>
        <Grid item sm={6} className="FlexEnd" style={{ zIndex: "0" }}>
          <img
            src={StartImg}
            alt="form"
            style={{ height: "212px", width: "332px" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-around"
        style={{ textAlign: "justify" }}
      >
        <Grid item sm={5} style={{ textAlign: "right" }}>
          <p className="StartScreenP">
            Mérd fel irodád digitalizáltságát önértékeléses tesztünkkel, hogy
            lásd, hol tartasz a többi hasonló könyvelő irodához képest és hogyan
            teheted még könnyebbé az életed!
          </p>
          <img src={StartImg2} alt="people-working" />
        </Grid>
        <Grid item sm={5} style={{ textAlign: "left" }}>
          <img src={StartImg1} alt="people-working" />
          <p className="StartScreenP">
            Digitalizálsz? Jövendő ügyfeleid egyre inkább digitális könyvelőt
            keresnek. Mutasd meg, hogy Te az vagy!
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StartScreen;
