import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { companyDataInput } from '../../../assets/texts/CompanyTexts';
import bg1 from '../../../assets/images/bg1.png';

class CompanyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      companyInfo: {
        name: '',
        email: '',
        sendmail: false,
        gdpr: false,
        founded: '',
        noofemployees: '',
        revenuebeforelast: '',
        revenuelast: '',
        clientsbeforelast: '',
        clientslast: '',
        clients0m50m: '',
        clients51m200m: '',
        clients201m500m: '',
        clients500m: '',
        lastquestion: [],
      },
    };
  }
  handleNext = () => {
    if (this.state.activeStep === 1 && !this.state.companyInfo.gdpr) {
      alert('Kérjük fogadja el az adatkezelési nyilatkozatot!');
    } else if (this.state.activeStep < companyDataInput.length - 1) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    } else if (this.state.activeStep === companyDataInput.length - 1) {
      this.props.saveCompanyInfo(this.state);
    }
  };
  handleBack = () => {
    if (this.state.activeStep > 0) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };
  handleTextInput = (event) => {
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  handleSelectButton = (id, value) => {
    let answers = [];
    let existing = false;
    if (id === 'lastquestion') {
      if (this.state.companyInfo.lastquestion.length > 0) {
        this.state.companyInfo.lastquestion.forEach((i, index) => {
          if (value === i) {
            existing = true;
            answers.splice(index, 1);
          } else {
            answers.push(i);
          }
        });
      }
      if (!existing) {
        answers.push(value);
      }
      this.setState({
        companyInfo: { ...this.state.companyInfo, lastquestion: answers },
      });
    } else {
      if (this.state.activeStep < companyDataInput.length - 1) {
        this.setState({
          companyInfo: { ...this.state.companyInfo, [id]: value },
          activeStep: this.state.activeStep + 1,
        });
      }
      if (this.state.activeStep === companyDataInput.length - 1) {
        this.props.saveCompanyInfo(this.state);
      }
    }
  };
  handleRadioButton = (event) => {
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        [event.target.id]: event.target.value,
      },
    });
  };
  setClassName = (answer) => {
    let cn = 'SelectButtonNoWS';
    this.state.companyInfo.lastquestion.forEach((i) => {
      if (i === answer) {
        cn = 'SelectButtonNoWS Selected';
      }
    });
    return cn;
  };
  setContent = (text) => {
    switch (text.type) {
      case 'TextInput':
      case 'NumberInput':
        return (
          <>
            <Grid
              container
              style={{ padding: '4px', margin: '8px 0px 8px 0px' }}
            >
              <Grid item xs={12} className='LabelText'>
                <p style={{ margin: 0 }}>{text.label}</p>
              </Grid>
              <Grid item xs={12} className='FlexCentered'>
                <input
                  className={text.type}
                  type='text'
                  id={text.id}
                  name={text.id}
                  key={text.id}
                  value={this.state.companyInfo[text.id]}
                  onChange={this.handleTextInput}
                />
              </Grid>
              {text.checkbox ? (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize='large' />}
                          checkedIcon={<CheckBoxIcon fontSize='large' />}
                          name='checked'
                          key={text.id}
                          checked={this.state.companyInfo.sendmail}
                          onChange={(event) => {
                            this.setState({
                              companyInfo: {
                                ...this.state.companyInfo,
                                sendmail: event.target.checked,
                              },
                            });
                          }}
                        />
                      }
                      label={
                        <p style={{ fontSize: '0.8em' }}>
                          Kérlek jelöld be, ha a későbbiekben megkereshetünk
                          emailben, hogy bemutassuk, hogy az eredményeid hogy
                          viszonyulnak a többi, hasonló méretű iroda átlagos
                          eredményeihez képest!
                        </p>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize='large' />}
                          checkedIcon={<CheckBoxIcon fontSize='large' />}
                          name='checked'
                          key={text.id}
                          checked={this.state.companyInfo.gdpr}
                          onChange={(event) => {
                            this.setState({
                              companyInfo: {
                                ...this.state.companyInfo,
                                gdpr: event.target.checked,
                              },
                            });
                          }}
                        />
                      }
                      label={
                        <p style={{ fontSize: '0.8em' }}>
                          Elolvastam és elfogadom az{' '}
                          <span>
                            <a
                              href='https://drive.google.com/file/d/1vPD0t4Jl1WeifR4QLcmiNeBUh_pxX8_1/view'
                              target='_blank'
                              rel='noreferrer'
                            >
                              Adatkezelési nyilatkozatot
                            </a>
                          </span>
                        </p>
                      }
                    />
                  </FormGroup>
                </>
              ) : null}
            </Grid>
          </>
        );
      case 'button':
        return (
          <>
            <Grid
              container
              justify='space-between'
              style={{ padding: '4px', margin: '8px 0px 8px 0px' }}
            >
              <Grid
                item
                xs={12}
                className='LabelText'
                style={{ marginBottom: '8px' }}
              >
                <p style={{ margin: 0 }}>{text.label}</p>
              </Grid>
              {text.options.map((item) => {
                return text.id !== 'lastquestion' ? (
                  <Grid item sm={2} xs={12} style={{ marginTop: '1em' }}>
                    <Button
                      onClick={() => {
                        this.handleSelectButton(text.id, item.value);
                      }}
                      className={
                        this.state.companyInfo[text.id] === item.value
                          ? 'SelectButton Selected'
                          : 'SelectButton'
                      }
                    >
                      {item.label}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} style={{ marginTop: '1em' }}>
                    <Button
                      onClick={() => {
                        this.handleSelectButton(text.id, item.value);
                      }}
                      className={this.setClassName(item.value)}
                    >
                      {item.label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </>
        );
      case 'radiobutton':
        return (
          <>
            <Grid
              container
              style={{ padding: '4px', margin: '8px 0px 8px 0px' }}
            >
              <Grid item xs={12} className='LabelText'>
                <p style={{ margin: 0 }}>{text.label}</p>
              </Grid>
              <Grid container justify='flex-start'>
                <Grid
                  container
                  sm={12}
                  direction='row'
                  justify='flex-end'
                  style={{ alignSelf: 'flex-end', position: 'relative' }}
                >
                  {text.radiobuttons.map((item) => {
                    return (
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        className='FlexCentered Border MV'
                        style={{
                          margin: '0.2em',
                          marginRight: 0,
                        }}
                      >
                        <p className='RadioValues'>{item.label}</p>
                      </Grid>
                    );
                  })}
                </Grid>
                {text.options.map((item) => {
                  return (
                    <Grid container direction='row' justify='space-between'>
                      <Grid item xs={5}>
                        <p className='RadioLabel'>{item.label}</p>
                      </Grid>
                      {text.radiobuttons.map((i) => {
                        return (
                          <Grid item xs={1} className='FlexCentered'>
                            <label className='RadioContainer'>
                              <input
                                type='radio'
                                id={item.id}
                                key={item.id}
                                name={item.id}
                                value={i.value}
                                onChange={this.handleRadioButton}
                              />
                              <span className='RadioCheckmark'></span>
                            </label>
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    return (
      <div className='Container'>
        <h2 className='HeaderText'>{`Alapadatok`}</h2>
        <span className='StepperPercent'>{`${Math.round(
          ((this.state.activeStep + 1) / 10) * 100
        )}%`}</span>
        <MobileStepper
          style={{
            backgroundColor: 'white',
            justifyContent: 'space-around',
          }}
          variant='progress'
          steps={10}
          position='static'
          activeStep={this.state.activeStep}
          /*   className={classes.root} */
        />
        <Grid container justify='space-between'>
          <Button
            className='StepperBack'
            size='small'
            onClick={this.handleBack}
            disabled={this.state.activeStep === 0}
          >
            Vissza
          </Button>
          <Button
            className='StepperNext'
            size='small'
            onClick={this.handleNext}
          >
            Tovább
          </Button>
        </Grid>
        {this.setContent(companyDataInput[this.state.activeStep])}
        <div style={{ textAlign: 'center' }}>
          <img src={bg1} alt='paper' />
        </div>
      </div>
    );
  }
}

export default CompanyData;
