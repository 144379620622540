//force deploy

export const companyDataInput = [
  {
    label:
      'Kérlek add meg a vállalkozásod nevét, ahogyan a Cégjegyzékben / EV Nyilvántartóban szerepel.',
    type: 'TextInput',
    id: 'name',
  },
  {
    label: 'Kérlek add meg az email címedet, amire az értékelést elküldhetjük.',
    type: 'TextInput',
    id: 'email',
    checkbox: true,
  },
  {
    label:
      'Kérlek add meg, hogy mikor alapítottad az irodádat / mikor alakult az irodád (év).',
    type: 'NumberInput',
    id: 'founded',
  },
  {
    label:
      'Kérlek add meg, hányan dolgoztok jelenleg az irodádban, Veled együtt (könyvelési területen).',
    type: 'button',
    id: 'noofemployees',
    options: [
      { label: '1 fő', value: '1' },
      { label: '2-5 fő', value: '2-5' },
      { label: '6-10 fő', value: '6-10' },
      { label: '11-20 fő', value: '11-20' },
      { label: '20 fő felett', value: '20+' },
    ],
  },
  {
    label: 'Kérlek add meg, mennyi volt a 2022. évi árbevétel (millió Ft).',
    type: 'NumberInput',
    id: 'revenuebeforelast',
    options: [
      { label: `0-10 \n millió Ft`, value: '0-10' },
      { label: `11-50 \n millió Ft`, value: '11-50' },
      { label: `51-200 \n millió Ft`, value: '51-200' },
      { label: `201-2000 \n millió Ft`, value: '201-2000' },
      { label: `2 mrd \n  Ft felett`, value: '2000+' },
    ],
  },
  {
    label: 'Kérlek add meg, mennyi volt a 2023. évi árbevétel (millió Ft).',
    type: 'NumberInput',
    id: 'revenuelast',
    options: [
      { label: `0-10 \n millió Ft`, value: '0-10' },
      { label: `11-50 \n millió Ft`, value: '11-50' },
      { label: `51-200 \n millió Ft`, value: '51-200' },
      { label: `201-2000 \n millió Ft`, value: '201-2000' },
      { label: `2 mrd \n  Ft felett`, value: '2000+' },
    ],
  },
  {
    label: 'Kérlek add meg, hány ügyfelet könyvelt az irodád 2022. év végén.',
    type: 'NumberInput',
    id: 'clientsbeforelast',
  },
  {
    label: 'Kérlek add meg, hány ügyfelet könyvelt az irodád 2023. év végén.',
    type: 'NumberInput',
    id: 'clientslast',
  },
  {
    label:
      'Milyen arányban oszlanak meg az ügyfeleid az alábbi kategóriák között jelenleg (2023. év végi adatok alapján)?',
    type: 'radiobutton',
    options: [
      {
        label: '50 millió Ft alatti éves árbevételű cégek, egyéni vállalkozók',
        id: 'clients0m50m',
      },
      {
        label: '51-200 millió Ft közötti éves árbevételű vállalkozások',
        id: 'clients51m200m',
      },
      {
        label: '201-500 millió Ft közötti éves árbevételű cégek, cégcsoportok',
        id: 'clients201m500m',
      },
      {
        label: '500 millió Ft éves árbevétel feletti cégek, cégcsoportok',
        id: 'clients500m',
      },
    ],
    radiobuttons: [
      { label: `Nem \njellemző\n (0-20%)`, value: '0-20%' },
      { label: `Előfordul \n(21-40%)`, value: '21-40%' },
      { label: `Jellemző \n(41-60%)`, value: '41-60%' },
      { label: `Nagyon \njellemző \n(61-80%)`, value: '61-80%' },
      { label: `Szinte \ncsak ilyen \n(81-100%)`, value: '81-100%' },
    ],
  },
  {
    label:
      'Egyetértesz az alábbiak közül egyik, vagy több állítással? (Több válasz is bejelölhető)',
    multiple: true,
    type: 'button',
    id: 'lastquestion',
    options: [
      {
        label: `A digitalizáció kapcsán nehézséget jelent nekem, hogy nem ismerem, hogy pontosan milyen problémákra milyen megoldások léteznek a piacon`,
        value:
          'A digitalizáció kapcsán nehézséget jelent nekem, hogy nem ismerem, hogy pontosan milyen problémákra milyen megoldások léteznek a piacon',
      },
      {
        label: `A digitalizáció kapcsán nehézséget jelent nekem, hogy a megfelelőnek tűnő megoldásokat összehasonlítsam, kiválasszam a számomra legjobbat`,
        value:
          'A digitalizáció kapcsán nehézséget jelent nekem, hogy a megfelelőnek tűnő megoldásokat összehasonlítsam, kiválasszam a számomra legjobbat',
      },
      {
        label: `A digitalizáció kapcsán nehézséget jelent nekem, hogy a előfizetési / licenc díjakat érvényesítsem a könyvelési díjakban`,
        value:
          'A digitalizáció kapcsán nehézséget jelent nekem, hogy a előfizetési/licenc díjakat érvényesítsem a könyvelési díjakban',
      },
      {
        label: `Egyik fenti állítással sem értek egyet`,
        value: 'Egyik fenti állítással sem értek egyet',
      },
    ],
  },
];
