import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { RadarChart } from "../../../components/radarChart/radarChart";
import gb from "../../../assets/images/gigabyte.png";
import mb from "../../../assets/images/megabyte.png";

function Badge({ ...props }) {
  const [todos, setTodos] = React.useState(false);
  const getBadge = () => {
    let digitalBadge = [];
    if (props.badge === "gigabyte") {
      digitalBadge = [
        <img src={gb} alt="badge-golden" className="BadgeStyle" />,
      ];
    } else if (props.badge === "megabyte") {
      digitalBadge = [
        <img src={mb} alt="badge-silver" className="BadgeStyle" />,
      ];
    } else digitalBadge = [<></>];
    return digitalBadge;
  };
  const displayTodos = (todos) => {
    let labels = {
      accounting: "Adatrögzítés / könyvelés",
      documentmgmt: "Dokumentum kezelés",
      taskmgmt: "Feladat menedzsment",
      onboarding: "Onboarding",
      communication: "Kommunikáció",
      marketing: "Marketing / Ügyfélszerzés",
    };
    let todosArray = [];
    for (let i in todos) {
      let todosPushed = 0;
      let emptyTodos = true;
      todos[i].forEach((item) => {
        if (item.length > 0) {
          emptyTodos = false;
        }
      });
      if (!emptyTodos) {
        todosArray.push(
          <>
            <Grid item sm={12}>
              <h2
                className="FlexCentered"
                style={{
                  fontSize: "1.4em",
                  textAlign: "center",
                  marginBottom: "0.5em",
                  marginTop: "0em",
                }}
              >
                {labels[i]}
              </h2>
            </Grid>
          </>
        );
        todos[i].forEach((item) => {
          /*   console.log(item); */
          if (i === "accounting" && todosPushed < 5 && item.length > 0) {
            todosPushed = todosPushed + 1;
            todosArray.push(
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>{item}</p>
            );
            /* return (
                  <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                    {item}
                  </p>
                ); */
          } else if (todosPushed < 3) {
            todosPushed = todosPushed + 1;
            todosArray.push(
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>{item}</p>
            );
          }
        });
      }
    }
    return todosArray;
  };
  const displayData = (badge) => {
    switch (badge) {
      case "megabyte":
        return [
          <>
            <Grid item sm={12}>
              <h2
                className="FlexCentered"
                style={{
                  fontSize: "1.4em",
                  textAlign: "center",
                  marginBottom: "0.5em",
                  marginTop: "0em",
                }}
              >
                <span className="FlexCentered">{getBadge()}</span>
                <span>
                  Gratulálunk! Válaszaid alapján kijelenthetjük, hogy mind a
                  használt megoldások, eszközök terén, mind tudásod és
                  hozzáállásod alapján kiérdemelted a{" "}
                  <span style={{ color: "#0097a7" }}>
                    Ezüst Digitális Könyvelő
                  </span>{" "}
                  könyvelő kategóriát!
                </span>
              </h2>
            </Grid>
            <Grid item sm={12}>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Nem csak a saját munkádat teszed egyszerűbbé az okos megoldások
                segítségével, de ügyfeleidet is támogatod - legyen szó szakmai
                kérdésekről, vagy éppen a digitális megoldások alkalmazásáról.
                Pontosan érted, milyen előnyökkel jár, ha nem csak Te használsz
                digitális, okos megoldásokat, de ügyfeleid is, így ebben is
                igyekszel legjobb tudásod szerint támogatni őket. Nem egyszerű
                eligazodni az egyre több és egyre nagyobb tudású digitális
                megoldások rengetegében, de ezidáig úgy tűnik, remekül navigálod
                könyvelő irodádat és ügyfeleidet.
              </p>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Mivel azonban a könyveléshez, ügyvitelhez kapcsolódó digitális
                megoldások rengetege folyamatosan lüktet, változik, gyarapszik,
                a minősítésed egy évre szól - mi is időről időre frissítjük,
                aktualizáljuk a kérdéseket és a válaszlehetőségeket, hogy azok
                naprakészek és relevánsak legyenek. Egy év múlva természetesen
                újra elvégezheted a minősítést és újra megszerezheted a címed.
              </p>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Mi pedig néhány gyakorlatias tanáccsal, útjelzővel szeretnénk
                ehhez hozzájárulni.
              </p>
            </Grid>
          </>,
        ];
      case "gigabyte":
        return [
          <>
            <Grid item sm={12}>
              <h2
                className="FlexCentered"
                style={{
                  fontSize: "1.4em",
                  textAlign: "center",
                  marginBottom: "0.5em",
                  marginTop: "0em",
                }}
              >
                <span className="FlexCentered">{getBadge()}</span>
                <span>
                  Gratulálunk! Válaszaid alapján kijelenthetjük, hogy mind a
                  használt megoldások, eszközök terén, mint tudásod és
                  hozzáállásod alapján kiérdemelted a legmagasabb szintű,{" "}
                  <span style={{ color: "#0097a7" }}>
                    Arany Digitális Könyvelő
                  </span>{" "}
                  könyvelő kategóriát!
                </span>
              </h2>
            </Grid>
            <Grid item sm={12}>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Nem csak a saját munkádat teszed egyszerűbbé az okos megoldások
                segítségével, de ügyfeleidet is támogatod - legyen szó szakmai
                kérdésekről, vagy éppen a digitális megoldások alkalmazásáról.
                Pontosan érted, milyen előnyökkel jár, ha nem csak Te használsz
                digitális, okos megoldásokat, de ügyfeleid is, így ebben is
                igyekszel legjobb tudásod szerint támogatni őket. Nem egyszerű
                eligazodni az egyre több és egyre nagyobb tudású digitális
                megoldások rengetegében, de ezidáig úgy tűnik, remekül navigálod
                könyvelő irodádat és ügyfeleidet.{" "}
              </p>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Mivel azonban a könyveléshez, ügyvitelhez kapcsolódó digitális
                megoldások rengetege folyamatosan lüktet, változik, gyarapszik,
                a minősítésed egy évre szól - mi is időről időre frissítjük,
                aktualizáljuk a kérdéseket és a válaszlehetőségeket, hogy azok
                naprakészek és relevánsak legyenek. Egy év múlva természetesen
                újra elvégezheted a minősítést és újra megszerezheted a címed.
              </p>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Mi pedig néhány gyakorlatias tanáccsal, útjelzővel szeretnénk
                ehhez hozzájárulni.
              </p>
            </Grid>
          </>,
        ];
      default:
        return [
          <>
            <Grid item sm={12}>
              <h2
                className="FlexCentered"
                style={{
                  fontSize: "1.4em",
                  textAlign: "center",
                  marginBottom: "0.5em",
                  marginTop: "0em",
                }}
              >
                Gratulálunk!
              </h2>
            </Grid>
            <Grid item sm={12}>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Látjuk, hogy nagyon sok területen nagyon sokat tettél már azért,
                hogy irodád működését, folyamataidat digitalizáld, automatizáld.
                Tudjuk, hogy nem egyszerű lépést tartani az egyre újabb és újabb
                megoldásokkal - ami ma a legokosabb megoldásnak számít, talán
                már fél év múlva is elavultnak számít. Éppen ezért mi is időről
                időre felülvizsgáljuk, aktualizáljuk a kérdéseket és a
                válaszlehetőségeket, hogy azok naprakészek és relevánsak
                legyenek. Ha úgy érzed, sokat léptél előre a minél
                teljeskörűbben automatizált, okos könyvelés felé vezető sárga
                köves úton, gyere vissza, és minősítsd magad újra!
              </p>
              <p style={{ fontSize: "0.9em", textAlign: "justify" }}>
                Mi pedig addig is néhány gyakorlatias tanáccsal, útjelzővel
                szeretnénk segíteni.
              </p>
            </Grid>
          </>,
        ];
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      {!todos ? (
        <>
          {displayData(props.badge)}
          <Grid item sm={12} className="FlexCentered" style={{ width: "100%" }}>
            <div
              style={{
                width: window.innerWidth < 500 ? "100%" : "65%",
                height: "65%",
                marginLeft: window.innerWidth < 500 ? "0em" : "-4em",
              }}
            >
              <RadarChart scores={props.scores} />
            </div>
          </Grid>
        </>
      ) : (
        <>{displayTodos(props.todos)}</>
      )}
      <Grid item sm={12} className="FlexCentered">
        <Button onClick={() => setTodos(!todos)} className="ButtonStyle">
          {!todos ? "Javaslatok megtekintése" : "Pontok megtekintése"}
        </Button>
      </Grid>
    </div>
  );
}

export default Badge;
