import React from "react";
import Paper from "@material-ui/core/Paper";
import StartScreen from "./screens/StartScreen";
import Welcome from "./screens/Welcome";
import CompanyData from "./screens/CompanyData";
import Questionnaire from "./screens/Questionnaire";
import Badge from "./screens/Badge";

import "./Survey.scss";

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startscreen: false,
      welcome: true,
      companyData: false,
      questionnaire: false,
      badge: "",
      scores: [],
      todos: {
        accounting: "",
        documentmgmt: "",
        taskmgmt: "",
        onboarding: "",
        communication: "",
        marketing: "",
      },
    };
  }
  sendData = (questions, todos, badge, results) => {
    let data = {
      date: new Date(),
      surveyID: `${Math.floor(Math.random() * 1000000000)}`,
      userinfo: this.state.companyInfo,
      survey: questions,
      todos: todos,
      badge: badge,
      results: results,
    };
    fetch(
      "https://v1kqa84hwb.execute-api.eu-west-1.amazonaws.com/prod/survey",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => console.log(response.json()))
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  startSurvey = () => {
    this.setState({ welcome: false, companyData: true });
  };
  saveCompanyInfo = (data) => {
    this.setState({ companyInfo: data.companyInfo });
    this.setState({ companyData: false, questionnaire: true });
  };
  saveAnswers = (data) => {
    this.setState({
      questions: data.questions,
      scores: data.scores,
    });
  };
  getAssessment = (questions) => {
    let badge = "no-badge";
    let maxScores = {
      marketing: 10,
      onboarding: 15,
      communication: 20,
      taskmgmt: 10,
      documentmgmt: 20,
      accounting: 59,
    };
    let results = {
      marketing: 0,
      onboarding: 0,
      communication: 0,
      taskmgmt: 0,
      documentmgmt: 0,
      accounting: 0,
    };
    let scoresArray = [];
    for (let i in questions) {
      if (i === "22" || i === "25") {
        let score = questions[i].score.reduce((a, b) => a + b, 0);
        results[questions[i].topic] = Math.round(
          results[questions[i].topic] + score
        );
      }
      if (i === "21") {
        let score = questions[i].score.sort((a, b) => a - b)[
          questions[i].score.length - 1
        ];
        results[questions[i].topic] = Math.round(
          results[questions[i].topic] + score
        );
      }
      if (i !== "21" && i !== "22" && i !== "25") {
        results[questions[i].topic] = Math.round(
          results[questions[i].topic] + questions[i].score
        );
      }
    }
    for (let i in results) {
      results[i] = Math.round((results[i] / maxScores[i]) * 100);
      scoresArray.push(results[i]);
    }
    if (
      results.marketing >= 50 &&
      results.onboarding >= 50 &&
      results.communication >= 50 &&
      results.taskmgmt >= 50 &&
      results.accounting >= 70 &&
      results.documentmgmt >= 70
    ) {
      this.setState({ badge: "gigabyte" });
      badge = "gold";
    } else if (
      results.marketing >= 40 &&
      results.onboarding >= 40 &&
      results.communication >= 40 &&
      results.taskmgmt >= 40 &&
      results.accounting >= 60 &&
      results.documentmgmt >= 60
    ) {
      this.setState({ badge: "megabyte" });
      badge = "silver";
    }
    this.setState({ questionnaire: false, scores: scoresArray });
    this.setTodos(questions);
    this.sendData(questions, this.setTodos(questions), badge, results);
  };
  showBadge = () => {
    if (
      !this.state.welcome &&
      !this.state.companyData &&
      !this.state.questionnaire
    ) {
      return (
        <Badge
          badge={this.state.badge}
          scores={this.state.scores}
          todos={this.state.todos}
        />
      );
    }
  };
  setTodos = () => {
    let questions = this.state.questions;
    let todos = {
      accounting: new Array(16),
      documentmgmt: new Array(10),
      taskmgmt: new Array(10),
      onboarding: new Array(10),
      communication: new Array(10),
      marketing: new Array(10),
    };
    for (let item in todos) {
      let tempArray = [];
      for (let i in questions) {
        if (
          i !== "21" &&
          i !== "22" &&
          i !== "25" &&
          questions[i].topic === item &&
          questions[i].prio < 999
        ) {
          tempArray[questions[i].prio] = questions[i].todo;
        }
        if (
          (i === "21" && questions[i].prio < 999) ||
          (i === "22" && questions[i].prio < 999) ||
          (i === "25" && questions[i].prio < 999)
        ) {
          questions[i].prio.forEach((piece, index) => {
            tempArray[piece] = questions[i].todo[index];
          });
        }
      }
      todos[item] = tempArray;
    }
    for (let i in todos) {
      let todoNr = 0;
      let tempArray = [];
      todos[i].forEach((item, index) => {
        if (item.length !== 0 && i === "accounting" && todoNr < 5) {
          tempArray.push(item);
          todoNr = todoNr + 1;
        }
        if (item.length !== 0 && i !== "accounting" && todoNr < 3) {
          tempArray.push(item);
          todoNr = todoNr + 1;
        }
      });
      todos[i] = tempArray;
      todoNr = 0;
      tempArray = [];
    }
    this.setState({ todos: todos });
    return todos;
  };

  render() {
    return (
      <Paper className="PaperContainer">
        {this.state.startscreen ? (
          <StartScreen
            startSurvey={() =>
              this.setState({ startscreen: false, welcome: true })
            }
          />
        ) : (
          <></>
        )}
        {this.state.welcome ? (
          <Welcome startSurvey={this.startSurvey} />
        ) : (
          <></>
        )}
        {this.state.companyData ? (
          <CompanyData saveCompanyInfo={this.saveCompanyInfo} />
        ) : (
          <></>
        )}
        {this.state.questionnaire ? (
          <Questionnaire
            saveAnswers={this.saveAnswers}
            getAssesment={this.getAssessment}
          />
        ) : (
          <></>
        )}
        {this.showBadge()}
      </Paper>
    );
  }
}

export default Survey;
