export const Questions = [
  {
    question: "Van a vállalkozásodnak honlapja, Facebook oldala?",
    id: "1",
    topic: "marketing",
    options: [
      { answer: "Igen, mindkettő", score: 5, prio: 999, todo: "" },
      {
        answer: "Igen, Facebook oldala",
        score: 4,
        prio: 2,
        todo: "A rendszeresen frissített, jól karbantartott Facebook oldal a közösségépítés mellett a marketing, értékesítés hatékony eszköze is lehet - és fontos előnye, hogy komolyabb informatikai tudás nélkül, egyszerűen kezelhető, valamint hogy lehetőség van értékeléseket gyűjteni. Azonban egy pillanatra gondolkodj el, mi történne, ha a Facebook egyik napról a másikra letiltaná a fiókodat, vagy egy algoritmus frissítés következtében nem jelennél meg a kedvelőid hírfolyamában, esetleg feltörik a fiókodat és hamis információkat kommunikálnak a nevedben. Az ilyen - sajnos nem példa nélküli - esetek miatt fontos, hogy legyen B terved: legyen weboldalad, gyűjtsd az email címeket, állj több lábon és amennyire csak lehetséges, függetlenítsd magad a fizetős tartalmaktól - és az algoritmusnak való kiszolgáltatottságtól. ",
      },
      {
        answer: "Igen, honlap",
        score: 4,
        prio: 3,
        todo: "A közösségi média a közösség- és márkaépítés mellett az értékesítés fontos csatornája is lehet. A céges Facebook (LinkedIn, Instagram) oldal költséghatékony módja az online jelenlétnek, és komolyabb IT tudás nélkül is egyszerű karbantartani, friss tartalommal ellátni. Fontos előny még, hogy az ügyfelek által írt értékelések megjelennek a Facebook oldalon - a honlap mellett pedig az értékelés az, ami az internetes keresések során a legtöbbet nyom a latban.  ",
      },
      {
        answer: "Egyik sincs",
        score: 0,
        prio: 1,
        todo: "A pandémia alatt egyértelművé vált, hogy az online jelenlét már nem lehetőség, hanem kötelező feladat minden vállalkozás számára - akkor is, ha úgy gondolod, hogy az ügyfeleid úgyis személyes ajánlás útján találnak meg. A közösségi média (Facebook, LinkedIn, Instagram) a közösség- és márkaépítés mellett az értékesítés fontos és hatékony csatornája is lehet. Olcsóbb formája az online jelenlétnek, mint egy honlap elkészítése - főleg, ha figyelembe vesszük, hogy a honlap értékesítési felületként csak akkor funkciónál igazán jól, ha az egy magasabb színvonalú, igényesen elkészített, folyamatosan frissített és informatív oldal. Ma már közel ugyanannyian keresnek interneten szakembereket, köztük könyvelőket, mint ahányan személyes ajánlás útján - és a szolgáltató honlapja mellett az ügyfél-értékelések nyomják a legtöbbet a latban. ",
      },
    ],
  },
  {
    question:
      "Hirdetsz bármilyen módon ügyfélszerzési / márkaépítési célból online csatornákon?",
    id: "2",
    topic: "marketing",
    options: [
      { answer: "Igen", score: 5, prio: 999, todo: "" },
      {
        answer: "Nem",
        score: 0,
        prio: 4,
        todo: "Az online jelenlét és az online felületeken történő, marketing célú megjelenés ma már alapelvárás: hiába gondolod úgy, hogy az ügyfeleid személyes ajánlás útján érkeznek, azért jellemzően az ismerős által ajánlott szakemberre 'rá is keresnek'. Amennyiben Google keresőbe beírják a szakember nevét, a képtalálatok alatt jellemzően az illető weboldala, privát Facebook vagy LinkedIn oldala jelenik meg az első találatok között. Amennyiben a könyvelőiroda nevére keresnek rá, jellemzően a vállalkozás Google Cégem oldala jelenik meg a találatok mellett nagy méretben, ahol az ügyfél-értékelések is láthatóak; ezt követően a weboldal és a céges Facebook oldal szokott megjelenni. Ha ezeken a felületeken a kereső semmit nem talál a keresett irodáról, az jelentős bizalomvesztést okozhat - és amennyiben a korábbi ajánlás és az online keresés során szerzett személyes benyomás távol áll egymástól, kis eséllyel végződik szerződéssel a történet.  ",
      },
    ],
  },
  {
    question:
      "Egységes folyamat mentén kezded meg az új ügyfeleiddel az együttműködést?",
    id: "3",
    topic: "onboarding",
    options: [
      {
        answer: "Nem",
        score: 0,
        prio: 1,
        todo: "Első benyomást nem tehetsz kétszer, tartja a mondás. Az új ügyfelek 'onboarding folyamata', a közös munka kezdete alapjaiban határozhatja meg a későbbi kapcsolatot - az elégedett ügyfél ajánlani fog az ismerőseinek is, hosszabb távon Veled marad, és nem mellékesen könnyebben elfogadja a magasabb könyvelési díjat is. Egy átlátható, egyértelmű - és lehetőleg minél inkább standardizált - onboarding folyamatban komfortosabban érzik magukat az ügyfeleid. Mivel jó esetben nem keresnek túl gyakran könyvelőt, nem rutinosak a teendőket illetően. Segíts nekik - és magadnak - egy jól dokumentált, részletetes folyamatleírással - ha látják, hogy pontosan mikor mi fog történni, az segít a bizalom építésében. ",
      },
      {
        answer:
          "Igen, nagyrészt egységes a folyamat, de nem, vagy csak részlegesen dokumentált",
        score: 2,
        prio: 2,
        todo: "Első benyomást nem tehetsz kétszer, tartja a mondás. Az új ügyfelek 'onboarding folyamata', a közös munka kezdete alapjaiban határozhatja meg a későbbi kapcsolatot - az elégedett ügyfél ajánlani fog az ismerőseinek is, hosszabb távon Veled marad, és nem mellékesen könnyebben elfogadja a magasabb könyvelési díjat is. Egy átlátható, egyértelmű - és lehetőleg minél inkább standardizált - onboarding folyamatban komfortosabban érzik magukat az ügyfeleid. Mivel jó esetben nem keresnek túl gyakran könyvelőt, nem rutinosak a teendőket illetően. Segíts nekik - és magadnak - egy jól dokumentált, részletetes folyamatleírással - ha látják, hogy pontosan mikor mi fog történni, az segít a bizalom építésében. ",
      },
      {
        answer:
          "Igen, és jellemzően dokumentált (digitális eszözzel / tudás management eszközzel támogatott) a folyamat",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Hogyan végzed az ügyfelek kockázati besorolását (teszel eleget a Pmt. szerinti kötelezettségeidnek)?",
    id: "4",
    topic: "onboarding",
    options: [
      {
        answer: "A cégadatokat és a kockázati besorolást manuálisan frissítem",
        score: 0,
        prio: 5,
        todo: "A Pmt. értelmében a könyvelőirodák kötelesek belső, a jogszabályi háttér előírásait és a vállalkozás sajátosságait is figyelembe vevő kockázatértékelést készíteni és azt írásban rögzíteni, naprakészen tartani és az illetékes hatóságok rendelkezésére bocsátani az engedélyezés, illetve az ellenőrzés során. A cégadatok frissítését, sőt a besorolást azonban nem kell manuállisan végezni, ma már léteznek a piacon digitális megoldások erre is (pl. Cégjelző a cégadatok frissítésére, vagy a DIA Pmt. asszisztens) - ezzel pedig értékes munkaidőt lehet felszabadítani.",
      },
      {
        answer:
          "A cégadatokat online szinkronizálom, de a kockázati besorolást én végzem",
        score: 3,
        prio: 6,
        todo: "A Pmt. értelmében a könyvelőirodák kötelesek belső, a jogszabályi háttér előírásait és a vállalkozás sajátosságait is figyelembe vevő kockázatértékelést készíteni és azt írásban rögzíteni, naprakészen tartani és az illetékes hatóságok rendelkezésére bocsátani az engedélyezés, illetve az ellenőrzés során. A cégadatok automatikus frissítését már egyre több könyvelőiroda használja, gratulálunk, hogy Ti is! Ha pedig a kockázati besorolást is automatizálnád, arra is vannak már megoldások a piacon (pl. DIA Pmt. asszisztens), ezáltal további értékes munkaórákat szabadíthatsz fel. ",
      },
      {
        answer:
          "A cégadatok szinkronizálása és a kockázati besorolás is automatikusan történik",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Melyik megközelítést választod az ügyfelekkel való együttműködés során támasztott elvárások tisztázására?",
    id: "5",
    topic: "onboarding",
    options: [
      {
        answer: "Mindent előre szoktunk tisztázni, és szerződésbe is foglaljuk",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "A lényeges pontokat előre tisztázzuk és szerződésbe is foglaljuk, a többit menet közben megbeszéljük",
        score: 3,
        prio: 4,
        todo: "Minél egyértelműbb a felek felelőssége és feladata, és ez írásban is megerősített, annál kisebb az esélye annak, hogy egy félreértésből elmérgesedő helyzet miatt véget ér a könyvelő és ügyfele együttműködése. ",
      },
      {
        answer:
          "Csak azokat a kérdéseket szoktuk előre tisztázni, amit az ügyfél kér",
        score: 0,
        prio: 3,
        todo: "Minél egyértelműbb a felek felelőssége és feladata, és ez írásban is megerősített, annál kisebb az esélye annak, hogy egy félreértésből elmérgesedő helyzet miatt véget ér a könyvelő és ügyfele együttműködése. ",
      },
    ],
  },
  {
    question:
      "Milyen módon tartod a kapcsolatot az ügyfeleiddel (a személyes találkozást ide nem értve)?",
    id: "6",
    topic: "communication",
    options: [
      {
        answer: "Általában telefonon beszélünk",
        score: 0,
        prio: 1,
        todo: "Az ügyfelekkel való kapcsolattartás csatornáit, módját a pandémia jelentősen átalakította, digitalizálódását jelentősen felgyorsította. A könyvelési anyagátadás módja egyre inkább digitalizálódik - egyrészt az online számla adatszolgáltatással a NAV rendszerében a számla adatok egyre inkább teljeskörűen elérhetőek, másrészt egyre több olyan megoldás áll rendelkezésre, amit könyvelő és ügyfele közösen használhat, és amely a könyvelő szoftverekhez is képes kapcsolódni (adat export/import, API kapcsolat). ",
      },
      {
        answer: "Telefon és email",
        score: 1,
        prio: 2,
        todo: "Az ügyfelekkel való kapcsolattartás csatornáit, módját a pandémia jelentősen átalakította, digitalizálódását jelentősen felgyorsította. A könyvelési anyagátadás módja egyre inkább digitalizálódik - egyrészt az online számla adatszolgáltatással a NAV rendszerében a számla adatok egyre inkább teljeskörűen elérhetőek, másrészt egyre több olyan megoldás áll rendelkezésre, amit könyvelő és ügyfele közösen használhat, és amely a könyvelő szoftverekhez is képes kapcsolódni (adat export/import, API kapcsolat). ",
      },
      {
        answer: "Telefon, email, video hívások (pl. Teams, Zoom, Meet stb .)",
        score: 3,
        prio: 3,
        todo: "Az ügyfelekkel való kapcsolattartás csatornáit, módját a pandémia jelentősen átalakította, digitalizálódását jelentősen felgyorsította. A könyvelési anyagátadás módja egyre inkább digitalizálódik - egyrészt az online számla adatszolgáltatással a NAV rendszerében a számla adatok egyre inkább teljeskörűen elérhetőek, másrészt egyre több olyan megoldás áll rendelkezésre, amit könyvelő és ügyfele közösen használhat, és amely a könyvelő szoftverekhez is képes kapcsolódni (adat export/import, API kapcsolat). ",
      },
      {
        answer:
          "Telefon, email, video hívások (pl. Teams, Zoom, Meet stb .) és közös interfész a dokumentum megosztásra (pl. Dropbox, Google Drive, DOQDrive, Smartbooks DokuFree stb.)",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Hány olyan ügyfeled van, akikkel évente max. 1-2 alkalommal találkozol személyesen, mert alapvetően online tartjátok a kapcsolatot?",
    id: "7",
    topic: "communication",
    options: [
      {
        answer: "Max. 10 (az ügyfeleim max. harmada)",
        score: 0,
        prio: 6,
        todo: "Az online könyvelésnek köszönhetően teljesen működőképes együttműködés lehet, ha könyvelő és ügyfele az ország két végében élnek és tevékenykednek, vagy akár az egyik fél külföldön. Ez jelentősen kibővíti a potenciális üzleti partnerek körét mind a könyvelőirodák, mind az ügyfeleik számára - és egyre több vállalkozás keres online könyvelést. ",
      },
      {
        answer: "10-20 (az ügyfeleim harmada-fele)",
        score: 2,
        prio: 7,
        todo: "Az online könyvelésnek köszönhetően teljesen működőképes együttműködés lehet, ha könyvelő és ügyfele az ország két végében élnek és tevékenykednek, vagy akár az egyik fél külföldön. Ez jelentősen kibővíti a potenciális üzleti partnerek körét mind a könyvelőirodák, mind az ügyfeleik számára - és egyre több vállalkozás keres online könyvelést. ",
      },
      {
        answer: "20-25 (az ügyfeleim több, mint fele)",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Majdnem az összes",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Szoktál rendszeresen hírlevelet küldeni az ügyfeleidnek az adóváltozásokról, vagy más fontos aktualitásról?",
    id: "8",
    topic: "communication",
    options: [
      {
        answer: "Nem szoktam",
        score: 0,
        prio: 4,
        todo: "Az email küldés még mindig az egyik leghatékonyabb online marketing eszköz, a B2B marketingesek 93%-a rendszeresen használja tartalommegosztás céljából, az online marketingesek 80%-a pedig egyenesen az ügyfélmegtartás nélkülözhetetlen kellékének tekinti. A hírlevél révén azon túl, hogy értéket adsz az ügyfeleidnek, nemcsak szakmai hitelességedet erősítheted a szemükben (például az aktuális adóváltozások hatásairól írt elemzéseid elküldésével), de forgalmat is terelhetsz a honlapodra (például blog cikkek megosztásával). ",
      },
      {
        answer: "Igen, évente 1 vagy 2 alkalommal",
        score: 2,
        prio: 5,
        todo: "Az email küldés még mindig az egyik leghatékonyabb online marketing eszköz, a B2B marketingesek 93%-a rendszeresen használja tartalommegosztás céljából, az online marketingesek 80%-a pedig egyenesen az ügyfélmegtartás nélkülözhetetlen kellékének tekinti. A hírlevél révén azon túl, hogy értéket adsz az ügyfeleidnek, nemcsak szakmai hitelességedet erősítheted a szemükben (például az aktuális adóváltozások hatásairól írt elemzéseid elküldésével), de forgalmat is terelhetsz a honlapodra (például blog cikkek megosztásával). ",
      },
      {
        answer: "Igen, évi 3-4 alkalommal",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Igen, legalább negyedévente, de inkább gyakrabban",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ha szoktál hírlevelet küldeni az ügyfeleidnek, milyen megoldást használsz erre?",
    id: "9",
    topic: "communication",
    options: [
      {
        answer: "CRM eszköz (MiniCRM, Hubspot, stb.)",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Email marketing eszköz (Mailchimp, ActiveCampaign, Sendgrid, Constant Contact stb.)",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Céges levelező (Outlook, GSuite, stb.)",
        score: 3,
        prio: 9,
        todo: "A hírlevél küldés akkor igazán hatékony marketing eszköz, ha a megnyitási, átkattintási stb. statisztikákat látod az egyes kampányok során - hiszen ez alapján tudod leszűrni a tanulságokat és finomhangolni a szöveget. Amennyiben a hírlevelet a levelező rendszeredből küldöd ki, ezen adatoknak nem leszel a birtokában. Érdemes egy email marketing kampányokat támogató eszközt, vagy CRM rendszert használni erre a célra (is). Ilyen megoldásokat ingyenes csomagban is találni a piacon (például a HubSpot CRM rendszer bizonyos kontaktszám alatt ingyenesen használható, de akár a MailChimp is alkalmas eszköz lehet testreszabott email-ek kiküldésére, vagy A/B tesztelésre is). ",
      },
      {
        answer: "Céges levelező (Gmail, YahooMail, Freemail, stb.)",
        score: 2,
        prio: 8,
        todo: "A hírlevél küldés akkor igazán hatékony marketing eszköz, ha a megnyitási, átkattintási stb. statisztikákat látod az egyes kampányok során - hiszen ez alapján tudod leszűrni a tanulságokat és finomhangolni a szöveget. Amennyiben a hírlevelet a levelező rendszeredből küldöd ki, ezen adatoknak nem leszel a birtokában. Érdemes egy email marketing kampányokat támogató eszközt, vagy CRM rendszert használni erre a célra (is). Ilyen megoldásokat ingyenes csomagban is találni a piacon (például a HubSpot CRM rendszer bizonyos kontaktszám alatt ingyenesen használható, de akár a MailChimp is alkalmas eszköz lehet testreszabott email-ek kiküldésére, vagy A/B tesztelésre is). Ha viszont a levelező rendszerből küldjük ki az emaileket, érdemes olyan email alkalmazást vagy előfizetői csomagot választani, amely adatbiztonság szempontjából magasabb szintű szolgáltatást nyújt (és kisebb eséllyel végzi a spam mappában), illetve amely iránt a potenciális ügyfelek is nagyobb bizalommal vannak (érdemes lehet kerülni például a freemail-es címeket).",
      },
    ],
  },
  {
    question:
      "Az ügyfelektől érkező kéréseket/kérdéseket, illetve a könyvelőirodán belüli feladatokat milyen módon vezeted, követed nyomon?",
    id: "10",
    topic: "taskmgmt",
    options: [
      {
        answer:
          "Feladatkezelő rendszert használok (Trello, JIRA, Microsoft Planner, JustDoo stb.)",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Emlékeztetőt írok a naptárba",
        score: 3,
        prio: 4,
        todo: "A könyvelőirodán belül nap mint nap számos 'ügyfél projekt' fut párhuzamosan: ügyfelek onboardálása, különböző bevallások készítése, NAV ellenőrzések kapcsán dokumentációk előkészítése, stb. Ezt az esetek nagy többségében ráadásul nem egy könyvelő kolléga látja el. Az ügyféligények hatékony kiszolgálása megköveteli, hogy ezeket a folyamatokat strukúráltan, átlátható módon lehessen nyomon követni - akár több kollégának egyszerre. Bizonyos méret, komplexitás alatt a naptár bejegyzések vagy Excel táblázatok is tökéletesen működőképes megoldások lehetnek, de egy ponton túl javasolt lehet feladatkezelő megoldást segítségül hívni (Trello, JIRA, stb.). ",
      },
      {
        answer: "Felírom jegyzet / Word / Excel stb. alkalmazásba",
        score: 2,
        prio: 3,
        todo: "A könyvelőirodán belül nap mint nap számos 'ügyfél projekt' fut párhuzamosan: ügyfelek onboardálása, különböző bevallások készítése, NAV ellenőrzések kapcsán dokumentációk előkészítése, stb. Ezt az esetek nagy többségében ráadásul nem egy könyvelő kolléga látja el. Az ügyféligények hatékony kiszolgálása megköveteli, hogy ezeket a folyamatokat strukúráltan, átlátható módon lehessen nyomon követni - akár több kollégának egyszerre. Bizonyos méret, komplexitás alatt a naptár bejegyzések vagy Excel táblázatok is tökéletesen működőképes megoldások lehetnek, de egy ponton túl javasolt lehet feladatkezelő megoldást segítségül hívni (Trello, JIRA, stb.). ",
      },
      {
        answer: "Felírom egy papírra",
        score: 1,
        prio: 2,
        todo: "A könyvelőirodán belül nap mint nap számos 'ügyfél projekt' fut párhuzamosan: ügyfelek onboardálása, különböző bevallások készítése, NAV ellenőrzések kapcsán dokumentációk előkészítése, stb. Ezt az esetek nagy többségében ráadásul nem egy könyvelő kolléga látja el. Az ügyféligények hatékony kiszolgálása megköveteli, hogy ezeket a folyamatokat strukúráltan, átlátható módon lehessen nyomon követni - akár több kollégának egyszerre. Bizonyos méret, komplexitás alatt a naptár bejegyzések vagy Excel táblázatok is tökéletesen működőképes megoldások lehetnek, de egy ponton túl javasolt lehet feladatkezelő megoldást segítségül hívni (Trello, JIRA, stb.). ",
      },
      {
        answer: "Fejben tartom őket",
        score: 0,
        prio: 1,
        todo: "A könyvelőirodán belül nap mint nap számos 'ügyfél projekt' fut párhuzamosan: ügyfelek onboardálása, különböző bevallások készítése, NAV ellenőrzések kapcsán dokumentációk előkészítése, stb. Ezt az esetek nagy többségében ráadásul nem egy könyvelő kolléga látja el. Az ügyféligények hatékony kiszolgálása megköveteli, hogy ezeket a folyamatokat strukúráltan, átlátható módon lehessen nyomon követni - akár több kollégának egyszerre. Bizonyos méret, komplexitás alatt a naptár bejegyzések vagy Excel táblázatok is tökéletesen működőképes megoldások lehetnek, de egy ponton túl javasolt lehet feladatkezelő megoldást segítségül hívni (Trello, JIRA, stb.). ",
      },
    ],
  },
  {
    question:
      "Milyen alapelv mentén döntitek el, hogy melyik ügyfél kérésre / kérdésre mikor válaszoltok?",
    id: "11",
    topic: "taskmgmt",
    options: [
      {
        answer: "A legsürgetőbbet vesszük előre (legközelebbi határidejű)",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "A kérdések/kérések beérkezési időpontja szerinti sorrendben haladunk a válaszokkal",
        score: 3,
        prio: 9,
        todo: "Az ügyféligények beérkezési sorrendben történő feldolgozása ugyan egyszerű és transzparens módszertan, azonban érdemes néhány további szempontot figyelmbe venni a priorizálás során. Ilyen például, hogy melyik ügyfelekhez kapcsolódnak a feladatok (az általuk fizetett könyvelési díj és igénybevett szolgáltatások, a kapcsolattartás módja, a kapcsolattartásra szánt idő, szemléletmód, stb.), mi a feladat becsült időigénye, melyik kollégának van szabad kapacitása, melyik kolléga mennyire tapasztalt a témában. Ezek áttekintését nagyban segítheti egy feladatkezelő rendszer: beállítható minden feladathoz többek között a becsült időráfordítás, a téma, a feladat státusza, felelőse, határideje. ",
      },
      {
        answer:
          "Nincs általános alapelvünk, az ügyfelekkel történő egyeztetés alapján döntjük el",
        score: 0,
        prio: 8,
        todo: "Az ügyféligények kezelése kapcsán fontos az ügyfelekkel történő egyeztetés, a kérések/kérdések pontosítása, a kontextus megértése - a feladatok priorizálása, kiosztása kapcsán azonban érdemes az alábbi szempontokat minden esetben figyelembe venni: melyik ügyfelekhez kapcsolódnak a feladatok (az általuk fizetett könyvelési díj és igénybevett szolgáltatások, a kapcsolattartás módja, a kapcsolattartásra szánt idő, szemléletmód, stb.), mi a feladat becsült időigénye, melyik kollégának van szabad kapacitása, melyik kolléga mennyire tapasztalt a témában. Ezek áttekintését nagyban segítheti egy feladatkezelő rendszer: beállítható minden feladathoz többek között a becsült időráfordítás, a téma, a feladat státusza, felelőse, határideje. ",
      },
      {
        answer: "A nagyobb/fontosabb ügyfelek kérdéseit előre szoktuk venni",
        score: 4,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Milyen eszközöket használsz az ügyfeleiddel a dokumentumok (számlák, bankkivonatok, stb.) megosztásra, hogyan kapod a könyvelési anyagokat?",
    id: "12",
    topic: "documentmgmt",
    options: [
      {
        answer:
          "Az anyagok többsége papír alapú, fizikailag hozzák el / kapom meg",
        score: 2,
        prio: 1,
        todo: "Az ügyfelek egy része még mindig papír alapú számlákat visz a könyvelőjének - azonban a NAV Online Számla rendszerében az összes belföldi adóalany által kibocsátott számla elérhető, így az már nem fordulhat elő, hogy egy számla kimarad és elfelejtődik. A papír alapú számlákat, anyagokat a későbbi tárolás, visszakereshetőség miatt is érdemes digitalizálni - a digitális anyagok pedig egyszerűen továbbíthatóak a megfelelő könyvelő programokba. Amennyiben az ügyfél továbbra is papír alapú számlákkal dolgozik jelentős részben, úgy - lehetőség szerint - érdemes lehet megkérni a könyvelési anyagok digitalizálására (scannelésére) és felhőbe történő feltöltésére (Google Drive, Dropbox, Tresorit, Quick, Smartbooks DokuFree, Cadren, stb.). Ezt könyvelési díj kedvezménnyel is ösztönözhető. ",
      },
      {
        answer: "Az anyagok többségét emailben küldi",
        score: 3,
        prio: 2,
        todo: "A könyvelési anyagok emailben történő átadása egy egyszerű és széles körben elterjedt módszer: az ügyfél az elektronikus (vagy papír alapú, scannelt) számlákat, letöltött bankszámla kivonatokat és egyéb anyagokat elküldi a könyvelőnek. A módszer legfőbb hátránya, hogy az anyagok jellemzően nem továbbíthatóak közvetlenül a könyvelő szoftverbe / nem szinkronizálhatóak a könyvelő szoftverrel vagy egy közbenső segédprogrammal. Érdemes lehet megfontolni az anyagok közös felületen, felhőben történő tárolását - ilyen, akár ingyenes megoldás lehet a Google Drive, a Dropbox, Tresirit, a Quick, a Smartbooks DokuFree, vagy épp a Cadren megoldása, hogy csak néhány példát említsünk. Ez könyvelési díj kedvezménnyel is ösztönözhető. ",
      },
      {
        answer:
          "Közös segédprogramot használunk, ahova az ügyfél feltölti az anyagokat (pl. GDrive, Dropbox, Smartbooks DokuFree, Cadren stb.)",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Közös könyvelési rendszert / ERP rendszert használunk az ügyféllel ",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ha egy ügyfeled nem használ egy szerinted kézenfekvő digitális megoldást (pl. Dokumentum kezelő) akkor jellemzően hogyan szoktad ezt a helyzetet kezelni?",
    id: "13",
    topic: "documentmgmt",
    options: [
      {
        answer:
          "Felhívom rá a figyelmét, javaslom az általam is használt megoldást és segítek a beállításával / használatával kapcsolatban is",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Felhívom rá a figyelmét, javaslok 1-2 olyan megoldást, amit ismerek / használok",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Nem nagyon érek rá ilyenekkel foglalkozni",
        score: 0,
        prio: 4,
        todo: "Amennyiben az ügyfél jellemzően digitális formában adja át a könyvelési anyagokat, vagy legalább a nyitottság megvan a részéről erre, úgy érdemes lehet olyan megoldásokat javasolni neki, amiből a könyvelő program felé egyszerűen továbbíthatóak az anyagok, esetleg szinkronizálhatóak. Ilyen megoldás lehet pédául a Dropboxba történő feltöltés, ahonnan az adatok szinkronizálhatóak a Quick fiókkal - innen pedig a könyvelő programba továbbíthatóak, vagy a Smartbooks DokuFree megoldása. ",
      },
    ],
  },
  {
    question:
      "Tájékoztatod ügyfeleidet a könyvelőiroda adatfeldolgozói tevékenységéről (GDPR)?",
    id: "14",
    topic: "documentmgmt",
    options: [
      {
        answer: "Igen, és minden módosítás során így járok el",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Igen, szerződéskötéskor",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Kérésre igen",
        score: 3,
        prio: 6,
        todo: "A könyvelés bizalmi munka, és egy könyvelő rengeteg adattal dolgozik - köztük személyes adatokkal is, amelyek az Új Európai Adatvédelmi Rendelet (GDPR) hatálya alá tartoznak. Az adatvédelmi tájékozatód legyen ismert ügyfeleid számára: tudasd velül, hogy az adataik biztonságban vannak Nálad, azokkal nem élsz vissza, az adatvédelmi tájékoztatóban foglalt célokon kívül másra nem használod. Ez segít a bizalom építésében is. ",
      },
      {
        answer: "Nem",
        score: 0,
        prio: 5,
        todo: "A könyvelés bizalmi munka, és egy könyvelő rengeteg adattal dolgozik - köztük személyes adatokkal is, amelyek az Új Európai Adatvédelmi Rendelet (GDPR) hatálya alá tartoznak. Az adatvédelmi tájékozatód legyen ismert ügyfeleid számára: tudasd velül, hogy az adataik biztonságban vannak Nálad, azokkal nem élsz vissza, az adatvédelmi tájékoztatóban foglalt célokon kívül másra nem használod. Ez segít a bizalom építésében is.",
      },
    ],
  },
  {
    question:
      "Csapatodban van-e rendszergazda, kifejezetten IT munkatárás, vagy alkalmazol-e állandó megbízásban rendszergazdát, IT csapatot?",
    id: "15",
    topic: "documentmgmt",
    options: [
      {
        answer: "Igen",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Nem",
        score: 0,
        prio: 3,
        todo: "Egy könyvelőiroda számos szoftvert használ nap mint nap - ezek részt vesznek jelentős mennyiségű adat tárolásában, feldolgozásában, archiválásában. Az adatok elvesztése komoly károkat okozhat - de hasonlóan nagy problémát okozhat, ha például egy rosszul karbantartott szoftver nem frissül megfelelően (és például nem a legfrissebb adókulcsokat alkalmazza). A szoftverek és hardverek rendszeres karbantartása, naprakész vírusvédelemmel való ellátása (tűzfalak, kiber-támadásoktól védő rendszer működtetése), jogosultságok megfelelő beállítása, de akár a megfelelő hőmérséglet és folyamatos áramellátás biztosítása mind kiemelt fontosságú terület egy könyvelőiroda számára, ami indokolttá teszi a megfelelő szakértő munkaviszonyban vagy állandó megbízásban történő alkalmazását. ",
      },
    ],
  },
  {
    question: "A könyvelési feladatok mekkora részét tudod távolról elvégezni?",
    id: "16",
    topic: "accounting",
    options: [
      {
        answer:
          "Max. 25%, kénytelen vagyok a feladatok nagy részét az irodából intézni",
        score: 0,
        prio: 1,
        todo: "Az ügyfelek egyre nagyobb része keresi az online könyvelést, ami alighanem összefügg az elekromos számlák térnyerésével is. A könyvelőirodák számára, akik képesek eleget tenni ennek az elvárásnak, kinyílik a potenciális ügyfelek köre - az többé már nem függ a földrajzi távolságtól, mivel nincs szükség havi rendszeres találkozásokra. Sőt, nem csak ügyfeleket lehet találni az ország másik végében, de kollégákat is: ha a könyvelési feladatok bárhonnan végezhetőek, nem számít, hogy a kolléga a közelemben ül, vagy épp 300 kilométerre tőlem. Érdemes lehet tehát felmérni, hogy milyen hardver és szoftver igénye van a biztonságos távoli munkavégzésnek - ez a pandémia alatt különösen felértékelődött. ",
      },
      {
        answer: "25-50%, rendszeresen be kell járnom",
        score: 2,
        prio: 2,
        todo: "Az ügyfelek egyre nagyobb része keresi az online könyvelést, ami alighanem összefügg az elekromos számlák térnyerésével is. A könyvelőirodák számára, akik képesek eleget tenni ennek az elvárásnak, kinyílik a potenciális ügyfelek köre - az többé már nem függ a földrajzi távolságtól, mivel nincs szükség havi rendszeres találkozásokra. Sőt, nem csak ügyfeleket lehet találni az ország másik végében, de kollégákat is: ha a könyvelési feladatok bárhonnan végezhetőek, nem számít, hogy a kolléga a közelemben ül, vagy épp 300 kilométerre tőlem. Érdemes lehet tehát felmérni, hogy milyen hardver és szoftver igénye van a biztonságos távoli munkavégzésnek - ez a pandémia alatt különösen felértékelődött. ",
      },
      {
        answer:
          "50-75%, időnként be kell menni egy-két dolgot elintézni, de jórészt otthonról megoldom a feladatokat",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "75% felett, gyakorlatilag mindent meg tudok oldani otthonról",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Amennyiben az ügyfél saját vállalat irányítási rendszert használ, jellemzően mennyi időnek kell eltelnie a szerződéskötés időpontjától, hogy abból a rendszerből könyvelési alkalmas adatokat fogadj?",
    id: "17",
    topic: "accounting",
    options: [
      {
        answer: "Nem veszek át",
        score: 0,
        prio: 3,
        todo: "Amennyiben egy közös, integrált rendszert használ könyvelő és ügyfél, úgy a könyvelési anyagátadás gyakorlatilag megszűnik. A könyvelő az ügyfél ERP rendszeréből minden fontos adatot elér, és akár automatizáltan fel is dolgoz. Ha az ügyfeled megkérdezi a véleményedet, hogy melyik programot használja, gondoldd át, hogy számodra melyik lenne a legjobb megoldás, amit közösen is tudtok használni - és javasold ezt neki, mert értékes munkaórákat spórolhatsz az ügyfelednek - és magadnak is. ",
      },
      {
        answer: "Pár hónap",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Pár hét",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Pár nap",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Pár óra",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ügyfeleid hány %-a esetében importálsz VEVŐI számlákat, vagy alakítottál ki/használsz ezzel kapcsolatos adatfogadásra alkalmas interfészt?",
    id: "18",
    topic: "accounting",
    options: [
      {
        answer: "Max. 25%",
        score: 0,
        prio: 10,
        todo: "A vevői számlák könyvelő programba történő integrálására ma már számos megoldás létezik, akár segédprogrammal (pl. Cadren, Quick, Adriana, Cashbook), vagy akár a könyvelő program is rendelkezhet ezzel a funkcióaval (pl. IMA, Maxi-Tax, Relax, Novitax, Könyvelness), így jelentősen csökkenthető a manuális munkavégzés - és ezzel a hibázás lehetősége is.",
      },
      {
        answer: "25-50%",
        score: 2,
        prio: 11,
        todo: "A vevői számlák könyvelő programba történő importálására ma már számos megoldás létezik, akár segédprogrammal (pl. Cadren, Quick, Adriana, Cashbook), vagy akár a könyvelő program is rendelkezhet ezzel a funkcióaval (pl. IMA, Maxi-Tax, Relax, Novitax, Könyvelness), így jelentősen csökkenthető a manuális munkavégzés - és ezzel a hibázás lehetősége is.",
      },
      {
        answer: "50-75%",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "75% felett",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ügyfeleid hány %-a esetében importálsz SZÁLLÍTÓI számlákat, vagy alakítottál ki/használsz ezzel kapcsolatos adatfogadásra alkalmas interfészt?",
    id: "19",
    topic: "accounting",
    options: [
      {
        answer: "Max. 25%",
        score: 0,
        prio: 14,
        todo: "A szállítói számlák könyvelő programba történő importálása ugyan némileg bonyolultabb téma, mint a vevői számlák importálása, azonban ma már erre is számos megoldás létezik (pl. SDSYS, Cadren, Quick, Billcity).",
      },
      {
        answer: "25-50%",
        score: 2,
        prio: 15,
        todo: "A szállítói számlák könyvelő programba történő importálása ugyan némileg bonyolultabb téma, mint a vevői számlák importálása, azonban ma már erre is számos megoldás létezik (pl. SDSYS, Cadren, Quick, Billcity).",
      },
      {
        answer: "50-75%",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "75% felett",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ügyfeleid hány %-a esetében importálsz BANKI TRANZAKCIÓKAT, vagy alakítottál ki/használsz ezzel kapcsolatos adatfogadásra alkalmas interfészt?",
    id: "20",
    topic: "accounting",
    options: [
      {
        answer: "Max 25%",
        score: 0,
        prio: 12,
        todo: "A banki tételek könyvelő programba történő importálása ma már számos megoldás létezik, akár segédprogrammal (pl. Adriana, Cashbook, Athéna), vagy akár a könyvelő program rendelkezik ezzel a funkcióaval (pl. IMA, Maxi-Tax, Relax), így jelentősen csökkenthető a manuális munkavégzés - és ezzel a hibázás lehetősége is. ",
      },
      {
        answer: "25-50%",
        score: 2,
        prio: 13,
        todo: "A banki tételek könyvelő programba történő importálása ma már számos megoldás létezik, akár segédprogrammal (pl. Adriana, Cashbook, Athéna), vagy akár a könyvelő program rendelkezik ezzel a funkcióaval (pl. IMA, Maxi-Tax, Relax), így jelentősen csökkenthető a manuális munkavégzés - és ezzel a hibázás lehetősége is. ",
      },
      {
        answer: "50-75%",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "75% felett",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Melyik könyvelőprogramo(ka)t használod az alábbiak közül? (Több válasz is bejelölhető)",
    id: "21",
    topic: "accounting",
    multiple: true,
    options: [
      {
        answer: "Apollo",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Cobra",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "ComboSoft",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "DOS alapú egyéb",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer: "Forint-Soft",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Hessyn",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "IMA",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Infotéka",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Kulcs-Szoft",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Libra",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Makrodigit",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Maxi-Tax",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Nagy Machinátor",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "Novitax",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "QualitySoft",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Relax",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "RLB",
        score: 3,
        prio: 999,
        todo: "",
      },

      {
        answer: "SAP",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Smartbooks",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "TenSoft",
        score: 3,
        prio: 999,
        todo: "",
      },

      {
        answer: "Webkönyvelő",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Egyéb:",
        score: 3,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Az alábbiak közül melyik megoldás(oka)t használod a könyvelési feladatok támogatására, gyorsítására? (Több válasz is bejelölhető)",
    id: "22",
    topic: "accounting",
    multiple: true,
    options: [
      {
        answer:
          "OCR (karakter felismerés), vagy NAV szinkron alapú segédprogram (Quick, BillCity, Cashbook stb.)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer: "Az ügyfél online számlázója (Számlázz.hu, Billingo, stb.)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "A szoftverem használ segédprogramokat (SDSYS, RSM Connectax, stb.)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer: "Banki adatfeldolgozást támogató segédprogram (pl. Adriana)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Ügyfél értesítések automatizálásához használt segédprogram (Viki, R!port, stb.)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Beszámoló / riport készítéshez segítséget nyújtó megoldás(ok) (R!port, ELZA, stb.)",
        score: 2,
        prio: 999,
        todo: "",
      },
      {
        answer: "Pénzügyi asszisztens (pl. OTP eBiz, autokassza)",
        score: 2,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ügyfeleid hány %-ra vagy képes egy tárgyhót megelőző hónapig pontos főkönyvet készíteni?",
    id: "23",
    topic: "accounting",
    options: [
      {
        answer: "Max 25%",
        score: 0,
        prio: 4,
        todo: "Amennyiben a főkönyvet nem - vagy csak kismértékben - kell manuálisan előállítani, úgy gyakorlatilag bármikor elérhető az aktuális állapot. Ehhez azonban javasolt többek között a számlaadatok rögzítését, kontírozását, banki tételekkel való összepontozását minél teljeskörűbben automatizálni. ",
      },
      {
        answer: "25-50%",
        score: 2,
        prio: 5,
        todo: "Amennyiben a főkönyvet nem - vagy csak kismértékben - kell manuálisan előállítani, úgy gyakorlatilag bármikor elérhető az aktuális állapot. Ehhez azonban javasolt többek között a számlaadatok rögzítését, kontírozását, banki tételekkel való összepontozását minél teljeskörűbben automatizálni. ",
      },
      {
        answer: "50-75%",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "75% felett",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Ügyfeleid hány %-a esetében készítesz rendszeresen riportokat a könyvelési anyagokból?",
    id: "24",
    topic: "accounting",
    options: [
      {
        answer: "Nem készítek",
        score: 0,
        prio: 6,
        todo: "Az ügyfelek részéről egyelőre nem általános elvárás, hogy a könyvelő havi szinten riportokat küldjön nekik a vállalkozás eredményeinek alakulásáról - ugyanakkor egyre több vállalkozó szeretné nyomon követni az eredmények alakulását. Amennyiben ezeket a riportokat, illetve az alapját képező könyvelést nem - vagy csak kismértékben - kell manuálisan előállítani, úgy gyakorlatilag bármikor elérhető az aktuális állapot. Ehhez azonban javasolt többek között a számlaadatok rögzítését, kontírozását, banki tételekkel való összepontozását minél teljeskörűbben automatizálni. ",
      },
      {
        answer: "Max 30%",
        score: 2,
        prio: 7,
        todo: "Az ügyfelek részéről egyelőre nem általános elvárás, hogy a könyvelő havi szinten riportokat küldjön nekik a vállalkozás eredményeinek alakulásáról - ugyanakkor egyre több vállalkozó szeretné nyomon követni az eredmények alakulását. Amennyiben ezeket a riportokat, illetve az alapját képező könyvelést nem - vagy csak kismértékben - kell manuálisan előállítani, úgy gyakorlatilag bármikor elérhető az aktuális állapot. Ehhez azonban javasolt többek között a számlaadatok rögzítését, kontírozását, banki tételekkel való összepontozását minél teljeskörűbben automatizálni. ",
      },
      {
        answer: "30-70%",
        score: 4,
        prio: 999,
        todo: "",
      },
      {
        answer: "70% felett",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Amennyiben a digitalizációnak köszönhetően felszabadult/felszabadulna időd, milyen területen segítenéd tanácsaiddal az ügyfeleidet? (Több válasz is bejelölhető)",
    multiple: true,
    id: "25",
    topic: "accounting",
    options: [
      {
        answer: "Pénzügyi terv összeállítása, átnézése",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer: "Ügyviteli / üzleti tanácsadás",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer: "Pályázatokkal kapcsolatos segítség",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer: "Bankválasztással kapcsolatos segítség",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer:
          "Informatikai tanácsadás - milyen szoftvereket, megoldásokat érdemes használni",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer: "Hitelekkel kapcsolatban",
        score: 0,
        prio: 999,
        todo: "",
      },
      {
        answer: "Egyéb:",
        score: 0,
        prio: 999,
        todo: "",
      },
    ],
  },
  {
    question:
      "Készítesz rendszeresen biztonsági másolatot az adataidról felhőalapú vagy fizikai szerverre?",
    id: "26",
    topic: "accounting",
    options: [
      {
        answer: "Igen, havonta",
        score: 5,
        prio: 999,
        todo: "",
      },
      {
        answer: "Igen, évi 3-4 alkalommal",
        score: 3,
        prio: 999,
        todo: "",
      },
      {
        answer: "Igen, évi 1-2 alkalommal",
        score: 2,
        prio: 9,
        todo: "Az adatok elvesztése, megrongálódása jelentős károkat okozhat, ennek megelőzése kiemelten fontos feladat. Az adatok biztonságos tárolása érdekében javasolt rendszeresen, legalább havonta biztonsági másolatot készíteni. A biztonsági másolat készülhet fizikai tárhelyre, vagy felhőe - ez utóbbi esetben érdemes olyan cégek szolgáltatásait igénybe venni, amelyek nagyobb szereplők a piacon, hosszabb ideje fennálló, megbízható működéssel. ",
      },
      {
        answer: "Nem szoktam",
        score: 0,
        prio: 8,
        todo: "Az adatok elvesztése, megrongálódása jelentős károkat okozhat, ennek megelőzése kiemelten fontos feladat. Az adatok biztonságos tárolása érdekében javasolt rendszeresen, legalább havonta biztonsági másolatot készíteni. A biztonsági másolat készülhet fizikai tárhelyre, vagy felhőe - ez utóbbi esetben érdemes olyan cégek szolgáltatásait igénybe venni, amelyek nagyobb szereplők a piacon, hosszabb ideje fennálló, megbízható működéssel. ",
      },
      {
        answer: "Nem szoktam, mivel felhőben tárolom az adatokat",
        score: 5,
        prio: 999,
        todo: "",
      },
    ],
  },
];
