import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import bg1 from "../../../assets/images/bg1.png";

function Welcome({ ...props }) {
  return (
    <div>
      <h2
        style={{
          fontSize: "1.4em",
          textAlign: "center",
          marginBottom: "2em",
        }}
      >
        Üdvözlünk a{" "}
        <span style={{ color: "#0097a7" }}>Digitális Könyvelő Minősítés</span>{" "}
        kérdőívben!
      </h2>
      <p className="GeneralText"> Gratulálunk!</p>
      <p className="GeneralText">
        Hiszen itt vagy, tehát fontos számodra a könyvelőirodád digitalizációja:
        vagy máris foglalkozol a folyamatok automatizálásával, vagy csak
        tervezed, és szeretnéd tisztábban látni a lehetőségeidet.
      </p>
      <p className="GeneralText">Vágjunk is bele, sok sikert!</p>
      <Grid container justify="space-around">
        <Grid item sm={6} className="FlexCentered">
          <Button onClick={props.startSurvey} className="ButtonStyle">
            Kitöltöm a kérdőívet
          </Button>
        </Grid>
        <Grid item sm={6} className="FlexCentered">
          <img src={bg1} alt="form" />
        </Grid>
      </Grid>
    </div>
  );
}

export default Welcome;
