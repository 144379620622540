import React from "react";
import Grid from "@material-ui/core/Grid";
import { Questions } from "../../../assets/texts/Questions";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";

import bg1 from "../../../assets/images/bg1.png";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      questions: {},
      completed: false,
      skip: false,
      other: "Egyéb:",
    };
  }
  handleNext = () => {
    if (this.state.activeStep === 7 && this.state.skip) {
      this.setState({ activeStep: this.state.activeStep + 2 });
    } else if (this.state.activeStep < Questions.length - 1) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    if (this.state.activeStep === Questions.length - 1) {
      this.props.getAssesment(this.state.questions);
    }
  };
  handleBack = () => {
    if (this.state.activeStep === 9 && this.state.skip) {
      this.setState({ activeStep: this.state.activeStep - 2 });
    } else if (this.state.activeStep > 0) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };
  handleSelect = (item, answer, score, prio, todo) => {
    console.log(answer, "score", score, "prio", prio);
    let answers = [];
    let scoresArray = [];
    let priosArray = [];
    let todosArray = [];
    let existing = false;
    if (item.multiple) {
      if (
        this.state.questions[item.id] !== undefined &&
        this.state.questions[item.id].answer
      ) {
        this.state.questions[item.id].answer.forEach((i, index) => {
          if (answer === i) {
            existing = true;
            answers.splice(index, 1);
            scoresArray.splice(index, 1);
            priosArray.splice(index, 1);
            todosArray.splice(index, 1);
          } else {
            answers.push(i);
            scoresArray.push(this.state.questions[item.id].score[index]);
            priosArray.push(this.state.questions[item.id].prio[index]);
            todosArray.push(this.state.questions[item.id].todo[index]);
          }
        });
      }
      if (!existing) {
        answers.push(answer);
        scoresArray.push(score);
        priosArray.push(prio);
        todosArray.push(todo);
      }
      this.setState({
        questions: {
          ...this.state.questions,
          [item.id]: {
            topic: item.topic,
            question: item.question,
            answer: answers,
            prio: priosArray,
            todo: todosArray,
            score: scoresArray,
          },
        },
      });
    } else {
      this.setState({
        questions: {
          ...this.state.questions,
          [item.id]: {
            topic: item.topic,
            question: item.question,
            answer: answer,
            prio: prio,
            todo: todo,
            score: score,
          },
        },
      });
      if (this.state.activeStep === 7 && answer === "Nem szoktam") {
        this.setState({ activeStep: this.state.activeStep + 2, skip: true });
      } else if (this.state.activeStep === 7 && answer !== "Nem szoktam") {
        this.setState({ activeStep: this.state.activeStep + 1, skip: false });
      } else if (this.state.activeStep !== Questions.length - 1) {
        this.setState({ activeStep: this.state.activeStep + 1 });
      }
      if (this.state.activeStep === Questions.length - 1) {
        this.setState({ completed: true });
        this.props.saveAnswers(this.state);
      }
    }
  };
  handleOptional = () => {
    if (this.state.activeStep) {
    }
  };
  setClassName = (item, answer) => {
    let cn = "SelectButtonNoWS";
    if (this.state.questions[item.id] !== undefined) {
      if (item.multiple) {
        this.state.questions[item.id].answer.forEach((i) => {
          if (i === answer) {
            cn = "SelectButtonNoWS Selected";
          }
        });
      }
      if (item.multiple === undefined) {
        if (this.state.questions[item.id].answer === answer) {
          cn = "SelectButtonNoWS Selected";
        }
      }
    }
    return cn;
  };
  render() {
    return (
      <div className="Container">
        <h2 className="HeaderText">{`Digitalizációval kapcsolatos kérdések`}</h2>
        {/*         <p
          style={{
            fontStyle: "italic",
            margin: "24px 8px 16px 8px",
            fontSize: "0.9em",
          }}
        >
          A következő oldalakon a működéseddel kapcsolatos kérdéseket teszünk
          fel, először azzal kapcsolatban, hogy hogyan lépsz kapcsolatba az
          ügyfeleiddel, hogyan kezditek a közös munkát.
        </p> */}
        <span className="StepperPercent">{`${Math.round(
          ((this.state.activeStep + 1) / Questions.length) * 100
        )}%`}</span>
        <MobileStepper
          style={{
            backgroundColor: "white",
            justifyContent: "space-around",
          }}
          variant="progress"
          steps={Questions.length}
          position="static"
          activeStep={this.state.activeStep}
        />
        <Grid container justify="space-between">
          <Button
            className="StepperBack"
            size="small"
            onClick={this.handleBack}
            disabled={this.state.activeStep === 0}
          >
            Vissza
          </Button>
          <Button
            className="StepperNext"
            size="small"
            onClick={this.handleNext}
          >
            {this.state.completed ? "Befejezés" : "Tovább"}
          </Button>
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{ padding: "4px", margin: "8px 0px 8px 0px" }}
        >
          <Grid item xs={12} className="LabelText">
            <p style={{ margin: 0 }}>
              {Questions[this.state.activeStep].question}
            </p>
          </Grid>
          <Grid container direction="row" style={{ marginTop: "1em" }}>
            {Questions[this.state.activeStep].options.map((item) => {
              return (
                <>
                  <Grid
                    item
                    xs={
                      window.innerWidth > 500
                        ? Questions[this.state.activeStep].question !==
                          "Melyik könyvelőprogramo(ka)t használod az alábbiak közül? (Több válasz is bejelölhető)"
                          ? 12
                          : 4
                        : Questions[this.state.activeStep].question !==
                          "Melyik könyvelőprogramo(ka)t használod az alábbiak közül? (Több válasz is bejelölhető)"
                        ? 12
                        : 6
                    }
                    style={{
                      marginTop: "1em",
                      padding:
                        Questions[this.state.activeStep].question !==
                        "Melyik könyvelőprogramo(ka)t használod az alábbiak közül? (Több válasz is bejelölhető)"
                          ? "0px"
                          : "0px 4px 0px 4px",
                    }}
                  >
                    {item.answer !== "Egyéb:" ? (
                      <>
                        <Button
                          onClick={() => {
                            this.handleSelect(
                              Questions[this.state.activeStep],
                              item.answer,
                              item.score,
                              item.prio,
                              item.todo
                            );
                          }}
                          className={this.setClassName(
                            Questions[this.state.activeStep],
                            item.answer
                          )}
                          style={{
                            justifyContent: "flex-start",
                            height: "fit-content",
                            whiteSpace: "normal !important",
                          }}
                        >
                          {item.answer}
                        </Button>
                      </>
                    ) : (
                      <>
                        {" "}
                        <input
                          className="TextInput"
                          style={{ margin: "0em", padding: "6px 0px 5px 0px" }}
                          type="text"
                          id="other"
                          name="other"
                          key="other"
                          placeholder="Egyéb"
                          value={
                            item.other ||
                            this.state[
                              `other${Questions[this.state.activeStep].id}`
                            ]
                          }
                          onChange={(event) => {
                            this.setState({
                              [`other${Questions[this.state.activeStep].id}`]:
                                event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.handleSelect(
                              Questions[this.state.activeStep],
                              event.target.value,
                              item.score,
                              item.prio,
                              item.todo
                            );
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
        <div style={{ textAlign: "center" }}>
          <img src={bg1} alt="paper" />
        </div>
      </div>
    );
  }
}

export default Questionnaire;
